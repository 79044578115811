import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

const BulletedDescription = ({ title, text, sx }) => {
    return (
        <Stack flexDirection="row" sx={{ ...sx }}>
            <li>
            </li>
            <Typography sx={{ fontSize: 16, fontWeight: 500, }}>
                {title}<span style={{ fontSize: 16, fontWeight: 400, }}>{" " + text}</span>
            </Typography>
        </Stack>
    )
}

export default BulletedDescription