import { Container, Typography } from '@mui/material'
import React from 'react'

const PageHeader = ({ text, subtext, sxText, sxSubText }) => {
    return (
        <div>
            <Typography sx={{ fontSize: 32, fontFamily: "DM Serif Text", ...sxText }}>{text}</Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 500, marginTop: 3, ...sxSubText }}>{subtext}</Typography>
        </div>
    )
}

export default PageHeader