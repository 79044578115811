import { CircularProgress, Container } from '@mui/material';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

function HomePage() {

    const navigate = useNavigate();

    useEffect(() => {
        navigate('/privacy-policy', { replace: true });
    }, []);
    return (
        <Container>
            <CircularProgress />
        </Container>
    )
}

export default HomePage