import { Box, Link, Stack, Typography } from '@mui/material'
import React from 'react'

const BulletedDescriptionWithLink = ({ title, beforeLinkText, afterLinkText, sx, link, url }) => {
    return (
        <Stack flexDirection="row" sx={{ ...sx }}>
            <li>
            </li>
            <Typography sx={{ fontSize: 16, fontWeight: 500, }}>
                {title}<span style={{ fontSize: 16, fontWeight: 400, }}>
                    {" " + beforeLinkText}
                    <span>
                        <Link href={url} underline="none">
                            {link}
                        </Link>
                    </span>
                    {afterLinkText !== null ? " " + afterLinkText : ""}
                </span>
            </Typography>
        </Stack>
    )
}

export default BulletedDescriptionWithLink