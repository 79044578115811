import AppUI from './config/theme';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BasePage from './pages/base_page/base_page';
import HomePage from './pages/home_page/home_page';
import PrivacyPolicyPage from './pages/privacy_policy_page/privacy_policy_page';
import TermsAndConditionsPage from './pages/terms_and_conditions/terms_and_conditions_page';

function App() {
  return (
    <AppUI>
      <Router>
        <Routes>
          <Route path='/' element={<BasePage page={<HomePage />} />} />
          <Route path='/privacy-policy' element={<BasePage page={<PrivacyPolicyPage />} />} />
          {/* <Route path='/terms-and-conditions' element={<BasePage page={<TermsAndConditionsPage />} />} /> */}
        </Routes>
      </Router>
    </AppUI>
  );
}

export default App;
