import { Container } from '@mui/material'
import React from 'react'

function BasePage({ page }) {
    return (
        <Container>
            {page}
        </Container>
    )
}

export default BasePage